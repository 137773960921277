import throttle from 'shared/utils/throttle'
import {
  ALGOLIA_SEARCH_LIST_SELECTOR,
  BREAKPOINT_MEDIUM,
  HITS_BASE_CLASSNAME,
} from 'views/consts'

export const SELECTOR_ALGOLIA_CONTAINER = '#algolia-instant-search'
export const CLASS_ADSLOT_CENTER = 'm-AdSlot__center'
export const CLASS_HIDDEN_IF_EMPTY = 'u-hidden-if-empty'
export const CLASS_PADDING_X_LARGE_TOP = 'u-padding-x-large--top'
export const CLASS_HIDDEN = 'u-hidden'
export const CLASS_MARGIN_XX_LARGE_TOP = 'u-margin-xx-large--top'

const THROTTLE_INTERVAL = 150
const MOBILE_HEADER_HEIGHT = 125
// This one is actually the size of u-padding-x-large--top or u-padding-xx-large which is 2rem
const PADDING_TOP = 32

/**
 * @typedef {Object} Coords
 * @property {number} top - offset on y axis in px
 * @property {number} left - offset on x axis in px
 */

const isLargeViewport = () => window.matchMedia(BREAKPOINT_MEDIUM).matches

/**
 * Gets top and left offest for the element without traversing DOM.
 *
 * @see {@link https://stackoverflow.com/a/26230989/2167740|Source}
 * @param {HTMLElement} element
 * @return {Coords}
 */
const getCoords = element => {
  const box = element.getBoundingClientRect()
  const { body, documentElement: docEl } = document
  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft
  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0
  const top = box.top + scrollTop - clientTop
  const left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}

/**
 * Helper fn that's supposed to be used in the conjuction with adition banners (GTM).
 *
 * It should solve following issue: Search results are hydrated but adition gets triggered before that. Unfortunately
 * that also means that when hydration is done banner get's cleared from DOM and replaced with new empty banner
 * wrapper. Problem here is speed of displaying banner, originally what was happening is:
 * 1. SSR html response
 * 2. Adition banner get's injected
 * 3. While iframe is loading hydration starts
 * 4. When hydration is done banner-placeholder/wrapper is again empty (without iframe)
 * 5. B/c of that we needed to add adition banner once more, when hydration is done, that was done in GTM scripts
 *
 * First issue is calling adition api twice for same banner placeholder and second was time needed for banner to be visible.
 * That time was hard blocked with hydration.
 *
 * This helper should fix both of these issues, logic is:
 * 1. SSR html response
 * 2. Helper fn clones original wrapper and places it outside of hydration area.
 * 3. Renames the original wrapper id (so it can't be targeted with adition api). This enables us to preserve original banner
 *    and have it shown during hydration time. That's accomplished by absolutely positioning clone over the original wrapper.
 * 4. Attach mutation observer to see if iframe will be injected in the clone or not (unfortunately there is no other
 *    way to know this).
 * 5. If there is iframe for that page then we also do some style stuff on both of the elements (original and cloned wrapper).
 *    If not, than we don't do style stuff.
 * 6. We also attach listener so that we can update coords for clone if viewport gets resized.
 *
 * @param {{slotName: string, callback: function, bannerHeight: number}} param
 * @return {undefined}
 */
export const appendSerpBanner = ({
  slotName,
  callback,
  bannerHeight = 140,
}) => {
  const bannerWrapper = document.querySelector(`#${slotName}`)
  if (!bannerWrapper || typeof callback !== 'function') {
    return
  }

  const algoliaContainer = document.querySelector(SELECTOR_ALGOLIA_CONTAINER)

  const cloneBanner = wrapper => {
    const bannerElement = wrapper.cloneNode(true)
    bannerElement.classList.add(CLASS_HIDDEN)
    // moving clone out of the hydration wrapper so it doesn't get cleaned
    algoliaContainer.parentElement.insertBefore(bannerElement, algoliaContainer)

    return bannerElement
  }

  // New SERP layout is too dense
  // TODO remove when merged with category pages
  const shouldIgnorePaddingTop = bannerElement => {
    const { isSearchPage, paddingTop, noPadding } = bannerElement.dataset

    return (
      noPadding === 'true' ||
      (isSearchPage === 'true' && paddingTop === 'false')
    )
  }

  const processBannerStyles = (bannerElement, wrapper) => {
    bannerElement.style.left = `${getCoords(wrapper).left}px`
    bannerElement.style.height = `${bannerHeight + PADDING_TOP}px`
    bannerElement.style.width = `${wrapper.getBoundingClientRect().width}px`

    // reason why we don't use getCoords for `top` is that value isn't the one that we need while
    // just getting `offsetTop` works like a charm. For `left` we have to use getCoords to get
    // correct, relative to parent, left offset.
    bannerElement.style.top = `${
      wrapper.offsetTop + (isLargeViewport() ? 0 : MOBILE_HEADER_HEIGHT)
    }px`
  }

  const processPlaceholderStyles = (bannerElement, placeholder) => {
    const ignoreTopPadding = shouldIgnorePaddingTop(bannerElement)

    placeholder.style.height = `${
      bannerHeight + (ignoreTopPadding ? 0 : PADDING_TOP)
    }px`
    placeholder.classList.remove(CLASS_HIDDEN_IF_EMPTY)
  }

  const prepareBanner = bannerElement => {
    // It's not ideal to set zIndex like this (without using z() scss method).
    // 1 is value that is generally quite safe to use we need it so that clone is clickable.
    bannerElement.style.zIndex = 1
    bannerElement.style.position = 'absolute'
    bannerElement.classList.remove(
      CLASS_HIDDEN,
      CLASS_PADDING_X_LARGE_TOP,
      CLASS_ADSLOT_CENTER,
      CLASS_HIDDEN_IF_EMPTY
    )

    if (bannerElement.dataset.noPadding !== 'true') {
      if (shouldIgnorePaddingTop(bannerElement)) {
        bannerElement.classList.add(CLASS_PADDING_X_LARGE_TOP)
      } else {
        bannerElement.classList.add(CLASS_MARGIN_XX_LARGE_TOP)
      }
    }
  }

  const updateBanner = ({ bannerElement, placeholder, isNew = true }) => {
    if (isNew) {
      placeholder.setAttribute('id', `${slotName}-wrapper`)
    }
    processPlaceholderStyles(bannerElement, placeholder)
    processBannerStyles(bannerElement, placeholder)
  }

  const getAlgoliaContainerObserver = bannerElement =>
    new MutationObserver(mutations => {
      const newPlaceholderAfterHydration = algoliaContainer.querySelector(
        `#${slotName}`
      )

      const oldPlaceholderAfterHydration = algoliaContainer.querySelector(
        `#${slotName}-wrapper`
      )

      for (let index = 0; index < mutations.length; index += 1) {
        const mutation = mutations[index]

        // case for page changes and when new placeholder is added
        if (newPlaceholderAfterHydration) {
          updateBanner({
            bannerElement,
            placeholder: newPlaceholderAfterHydration,
          })
          break
        }

        // covers cases where placeholder is not changed in container but we still need to update
        if (
          oldPlaceholderAfterHydration &&
          mutation.type === 'childList' &&
          mutation.target.className.includes(HITS_BASE_CLASSNAME)
        ) {
          updateBanner({
            bannerElement,
            placeholder: oldPlaceholderAfterHydration,
            isNew: false,
          })
          break
        }

        // Filters panel from the top (TODO remove or rework when the experiment either is ended or approved)
        if (
          mutation.type === 'attributes' &&
          mutation.target.className.includes?.(
            'e-ProductFilterVariant--filters'
          )
        ) {
          updateBanner({
            bannerElement,
            placeholder: oldPlaceholderAfterHydration,
            isNew: false,
          })
        }
      }
    })

  const getBannerObserver = (bannerElement, onComplete) =>
    new MutationObserver((mutations, observer) => {
      mutations.forEach((mutation, i) => {
        if (mutation.type === 'childList' && mutation.target.id === slotName) {
          // adjustments on original/old/wrapper for the banner
          processPlaceholderStyles(bannerElement, bannerWrapper)

          // adjustments done on the cloned one
          processBannerStyles(bannerElement, bannerWrapper)
          prepareBanner(bannerElement)
        }

        if (mutations.length <= i + 1) {
          onComplete(observer)
        }
      })
    })

  const init = () => {
    const banner = cloneBanner(bannerWrapper)

    // we need to rename original wrapper so that iframe gets injected into cloned one.
    bannerWrapper.setAttribute('id', `${slotName}-wrapper`)

    // usually, this should use adition api to add iframe
    callback()

    // we need to watch for mutation in order to know if banner is actually added or not. Only if it's added
    // we will run most of the necessary DOM manipulations. Otherwise queries which shouldn't show banner will
    // show banner wrapper but empty.
    getBannerObserver(banner, observer => {
      // This mutation is done only once (b/c we append addition banner only once)
      // so we can disconnect at the end of mutation list.
      observer.disconnect()

      // we start next mutation observer that will catch mutations caused by page changes.
      // this is needed so we can update position of banner over the new placeholder.
      const algoliaContainerObserver = getAlgoliaContainerObserver(banner)
      algoliaContainerObserver.observe(
        algoliaContainer.querySelector(ALGOLIA_SEARCH_LIST_SELECTOR),
        {
          childList: true,
          subtree: true,
          attributes: true,
        }
      )

      const productFilterVariantFiltersObserver = document.querySelector(
        '.e-ProductFilterVariant--filters'
      )
      if (productFilterVariantFiltersObserver) {
        algoliaContainerObserver.observe(productFilterVariantFiltersObserver, {
          childList: true,
          subtree: true,
          attributes: true,
        })
      }
    }).observe(banner, {
      childList: true,
      subtree: false,
      attributes: false,
    })

    // we need this to update coords if screen get resized b/c new banner is absolute
    window.addEventListener(
      'resize',
      throttle(
        () => processBannerStyles(banner, bannerWrapper),
        THROTTLE_INTERVAL
      )
    )
  }

  init()
}
